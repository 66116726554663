/* eslint-disable jsx-a11y/no-static-element-interactions */
import Image from 'next/image'
import { MutableRefObject, useEffect, useState } from 'react'

export class VideoManager {
  private videoRefs: Array<MutableRefObject<HTMLVideoElement | null>> = []

  public register(videoRef: MutableRefObject<HTMLVideoElement | null>) {
    this.videoRefs.push(videoRef)
  }

  public unregister(videoRef: MutableRefObject<HTMLVideoElement | null>) {
    this.videoRefs = this.videoRefs.filter((ref) => ref !== videoRef)
  }

  public pause() {
    this.videoRefs.forEach((videoRef) => {
      if (videoRef && videoRef.current) {
        videoRef.current.pause()
      }
    })
  }
}

export function SimpleVideo({
  src,
  autoPlay = false,
  controls = true,
  modalOpen,
  poster,
  videoRef,
  customClass,
}: {
  autoPlay?: boolean
  src: string
  controls?: boolean
  modalOpen?: boolean
  poster?: string
  videoRef?: MutableRefObject<HTMLVideoElement | null>
  customClass?: string
}) {
  const [isPlaying, setIsPlaying] = useState(false)
  const togglePlay = () => {
    if (videoRef && videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setIsPlaying(!isPlaying)
    }
  }
  useEffect(() => {
    if (modalOpen && videoRef && videoRef.current) {
      videoRef.current.play()
      setIsPlaying(true)
    }
  }, [modalOpen, videoRef])

  useEffect(() => {
    if (videoRef && videoRef.current) {
      if (videoRef.current.paused && isPlaying) {
        setIsPlaying(false)
      } else if (!videoRef.current.paused && !isPlaying) {
        setIsPlaying(true)
      }
    }
  }, [isPlaying, videoRef?.current, videoRef?.current?.paused, videoRef])

  return (
    <div
      className={`h-full video-container ${customClass}`}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- tabIndex needed for keyboard navigation
      tabIndex={0}
      onClick={togglePlay}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          togglePlay()
        }
      }}
    >
      {/*eslint-disable-next-line jsx-a11y/media-has-caption --  Captions hard edited into video file*/}
      <video
        className="h-full max-w-full md:h-u md:max-h-screen"
        src={src}
        autoPlay={autoPlay}
        ref={videoRef}
        preload="auto"
        playsInline={true}
        poster={poster}
        controls={controls}
      />
      {!isPlaying && (
        <div className="play-button">
          <Image
            src="https://media.foratravel.com/image/upload/v1715877472/PlayButton_joiipk.svg"
            alt="Play"
            width={100}
            height={100}
          />
        </div>
      )}
    </div>
  )
}
